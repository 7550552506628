<template>
  <div id="app">
    <div class="background"></div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted: function () {
    this.addHandler();
  },
  destroyed: function () {
    this.removeHandler();
  },
  methods: {
    addHandler: function () {
      window.addEventListener("keydown", this.toggleFullScreen);
    },
    removeHandler: function () {
      window.removeEventListener("keydown", this.toggleFullScreen);
    },
    toggleFullScreen: function (event) {
      if (event.key == ".") {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        }
      }
    },
  },
};
</script>

<style lang="sass">
*
  margin: 0
  padding: 0

.background
  position: absolute
  z-index: -1
  width: 100vw
  height: 100vh
  background-color: black
  background-size: cover
  background-repeat: no-repeat
  background-position: center center

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

button:focus
  outline: none
</style>
